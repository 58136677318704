// @import url('../../assets/styles/mixins' )
//  @use '../../assets/styles/_mixins' as * ;
//  @use './_mixins' as *;
@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.tabs {
  display: inline-flex;
  border-bottom: 1px solid #cacaca;
  width: 100%;
  overflow: auto;
  &.seal{
    border-bottom: 0px ;
    margin-top: 20px;
  }
  &.vertical {
    flex-direction: column;
    border-bottom: none;
    @include query('desktop') {
      flex-direction: row;
    }
    .tabs {
      &__item {
        margin: 0 1rem;
        max-width: 220px;
        
        button {
          text-decoration: underline;
          text-align: left;
        }
        &.active {
          border-bottom-color: transparent;
          button {
            text-decoration: none;
            font-weight: 500;
            text-shadow: none;
            color: #003bb3;
          }
        }
      }

      &__id {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 0.875rem;
        flex-shrink: 0;
        // @include query('desktop') {
        //   display: none;
        // }
      }
    }
  }

  &__item {
    display: inline-flex;
    font-size: 1rem;
    color: #7b7171;
    padding: 0.875rem 0;
    border-bottom: 3px solid transparent;
    padding-bottom: 1rem !important;
    margin: 0 0.5rem;
    position: relative;
    align-items: center;
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      color: #363740;
      border-color: #003bb3;
      
      .tabs__id {
        border-color: #003bb3;
      }
      button {
        text-shadow: 0.4px 0 0 currentColor;
      }
    }

    &.sm {
      padding-bottom: 0;
    }

    button {
      white-space: normal;
      display: flex;
      align-items: center;
      color: #7B7171;  
      font-size: 15px;
      }

    &-disabled {
      cursor: default;

      & button:disabled {
        cursor: default;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;
    &_seal {
      height: 120px;
      width: 120px;
      @include query('desktop') {
        height: 60px;
        width: 60px;
        // flex-direction: column;
        }
    }
  }

  &__label{
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;
    padding: 24px;
    margin-left:1rem;
    @include query('desktop') {
      padding: 11px;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      }
    &.active {
      color: #363740;
      border: 2px solid #003BB3;
      box-shadow: 0px 5px 40px rgba(34, 34, 34, 0.1);
      border-radius: 8px;

      
      .tabs__id {
        border-color: #003bb3;
      }
      button {
        text-shadow: 0.4px 0 0 currentColor;
      }
    }
  }

  &__id {
    display: none;
    border: 3px solid #dfe0eb;
  }

  &__alert-title {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.sm {
    border-bottom: none;
  }
}
