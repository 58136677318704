.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-spacing: 0px;
  border-collapse: inherit;
  &__header {
    th {
      padding: 0.8rem 0.8rem 0.8rem 0;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      // TODO: Add to colors variable
      color: #595959;
      border-bottom: 1px solid #e2e2e2;
      button {
        padding-left: 3px;
        cursor: pointer;
      }
    }
    &__secondary {
      th {
        background-color: #ededed;
        padding: 0.625rem;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  &__row {
    position: relative;
    border-bottom: 1px solid #e2e2e2;
    td {
      padding: 0.75rem 0.75rem 0.75rem 0;
      font-size: 0.865rem;
      vertical-align: middle;
    }
    td:first-child {
      padding: 0.75rem 0.75rem 0.75rem 0;
      font-size: 0.865rem;
      vertical-align: middle;
      width: 30%;
    }
    &__secondary {
      td {
        padding: 0.75rem;
        font-size: 0.865rem;
        vertical-align: middle;
        border-bottom: 1px solid #e2e2e2;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

.skeleton {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__shimmer {
    height: 42px;
    min-width: 250px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    animation: shimmer 3s infinite linear;
    margin-bottom: 1rem;
    &--sm {
      min-width: 60px;
      margin-left: 1rem;
    }
    &--lg {
      max-width: 400px;
      height: 24px;
    }
    &--slim {
      height: 24px;
      max-width: 350px;
    }
  }
  &__head {
    height: 54px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    animation: shimmer 1s infinite linear;
    margin-bottom: 2rem;
  }
  &__row {
    border-bottom: 1px solid #f8f9fb;
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 12px;
  }
  &__item {
    height: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    flex-grow: 1;
    animation: shimmer 2s infinite linear;
    &:not(:last-of-type) {
      margin-right: 50px;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

