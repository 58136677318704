.upload {
  height: fit-content;
  padding-bottom: 0.625rem;
  width: 100%;
  border-bottom: 1px solid #CECECE;
  margin-bottom: 1.625rem;
  &__div {
    position: relative;
    height: 175px;
    border-radius: 4px;
    border: 1px dashed #949494;
    padding: 0.625rem 0.75rem;
    width: 100%;
    border-color: #949494;
    cursor: pointer;
    .wrap,
    .wrap2 {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__image {
        width: 21px;
        height: 21px;
      }
      &__image2 {
        width: 100px;
        height: 100px;
      }
      &__image3 {
        width: 150px;
        height: 120px;
      }
      &__image,
      &__image2,
      &__image3 {
        img {
          width: 100%;
          height: 100%;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__label {
    color: #767676;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  &__info {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #7B7171;
    margin-top: 0.563rem;
  }
}.mt_2 {
  margin-top: 1.25rem;
}
.mb_2 {
  margin-bottom: 1.063rem;
}
.company_logo {
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:disabled {
    cursor: not-allowed;
  }
}
text {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  // text-transform: uppercase;
  letter-spacing: 5px;
  fill: #000;
}
.company_stamp {
  width: 200px;
  height: 100px;
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px;
  &_inner {
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .company_info {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &_top {
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 7px;
        color: #000;
        margin: 0 10px;
        text-transform: uppercase;
      }
    }
    &_bottom {
      font-family: 'Roboto', sans-serif;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 5px;
      color: #000;
      margin-top: 5px;
    }
  }
  .star_logo {
    height: 8px;
    width: 8px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.company_address {
  width: 100%;
  border-top: 0.5px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 7px;
  line-height: 4px;
  padding: 2px;
  box-sizing: border-box;
  div {
    margin-top: 4px;
  }
}
.company_location {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15px;
  border-bottom: 0.5px solid #000;
  span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 4px;
    color: #000;
    text-transform: uppercase;
  }
}
.star_logo {
  height: 8px;
  width: 8px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.company_info {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &_top {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 7px;
      color: #000;
      margin: 0 10px;
      text-transform: uppercase;
    }
  }
  &_bottom {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 5px;
    color: #000;
    margin-top: 5px;
  }
}
.personal_info {
  padding: 20px;
}
.seal_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .div1 {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    // border: 1px solid #4A494E;
  background-size:100% 100%;
  display:inline-block;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
    text {
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      letter-spacing: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #000;
    }
  }
  .div2 {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 2px dashed #4A494E;
    position: absolute;
    svg {
      width: 100%;
      height: 100%;
    }
    text {
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 5px;
      fill: #000;
      text-align: center;
    }
    .company_type {
      letter-spacing: 0px;
    }
  }
  .seal_title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #4A494E;
    z-index: 2;
  }
}

.parent {
  background-color: transparent;
  margin: 5px;
  width: 30px;
  height: 30px;
  position: relative;
  border: 2px solid black;
  border-radius: 50%;
}

.child1 {
  background-color: transparent;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  position: absolute;
  border: 2px solid black;
  border-radius: 50%;
}

#canvas {
  border-radius: 50%;
  letter-spacing: 20px;
}

#coy_number {
  position: absolute;
  top: 168px;
  left: -45px;
  font-weight: normal;
  font-size: 25px;
  font-family: "arial";
  width: 480px;
  text-align: center;
  text-transform: uppercase;
  /* color: blue; */
  /* color: #c1353f; */
  /* text-shadow: 3px 1px 0px #000; */
}

.input {
  text-transform: uppercase;
}

.payment__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  
  // /* flex-direction: column; 
  gap: 1rem;
  /* align-items: flex-start; */
  /* 
  justify-content: space-between; */
  // justify-content: center;
  align-items: center;
}

.payment__options .payment__option input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.payment__options .payment__option {
  cursor: pointer;
  margin-right: 5px;
}

.payment__options .payment__option .payment__option_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.payment__options .payment__option .payment__option-content img {
  /* margin-right: 25px; */
  height: 50px;
  width: 50px;
}

.payment__options .payment__option .payment__option-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.payment__options .payment__option input[type="radio"]:checked+.payment__option-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #003bb3;
  right: 5px;
  top: 5px;
  display: none;
  border-radius: 100%;
  border: 2px solid transparent;
  /* -webkit-box-shadow: 0px 0px 0px 2px #003bb3;
  box-shadow: 0px 0px 0px 2px #003bb3; */
}

.payment__options .payment__option input[type="radio"]:checked+.payment__option-content {
  border: 2px solid #e4e4e4;
  /* background: #eaf1fe; */
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.grey {
  color: rgb(152, 152, 152) !important;
}

.blue {
  color: #406dc6 !important;
}

.red {
  color: rgb(227, 125, 125) !important;
}

.preview {
  /* position: ; */
  top: 180px;
  right: 40px;
  width: 100px;
}