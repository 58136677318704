.avatar {
  &__thumbs {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 1rem;
    border: 2px solid #eef5ff;
  }
  &__placeholder {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    border: 2px solid #eef5ff;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    position: relative;
    &.dot {
      margin-right: 1.5rem;
      &:after {
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background: #2fa36b;
        right: -1.5rem;
        display: inline-block;
        margin-right: 0.5rem;
        position: absolute;
      }
    }
  }
}
