.sideBar {
  &__wrapper {
    width: 255px;
    padding-top: 27px;
    background-color: #FFF;
    display: inline-block;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1020;
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
    transition: all ease-in-out 0.3s;
    &.small {
      width: 64px;
      .menu {
        padding: 0 0.5rem 6.25rem;
        &__section {
          display: none;
        }
        &__label {
          display: none;
        }
        &__link {
          padding: 1rem 0rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &__icon {
          margin: 0;
        }
      }
      .sidebar {
        &__header {
          justify-content: center;
        }
      }
      .logo {
        display: none;
      }
    }
  }
}

.tinySidebar {
  width: 64px;
  height: 100vh;

  .sidebarHeader img {
    display: none;
  }

  .tinySidebar .sidebarHeader {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tinySidebar .hideBtnWrapper {
    padding: 10px;
    margin: 0 auto;
  }

  .tinySidebar .sidebarHeader i {
    background-image: url('../../assets/icons/hideMenuIcon.svg');
    color: #ffffff;
    cursor: pointer;
    background-size: cover;
    display: inline-block;
  }

  .tinySidebar .menu div p {
    display: none;
  }

  .tinySidebar .menu div {
    padding: 19px;
  }

  .newDocumentWrapper div p {
    display: none;
  }

  .newDocumentWrapper {
    max-width: 40px;
    padding-top: 12px;
    width: 100%;
    border-radius: 17px;
  }

  .newDocumentWrapper div {
    border: none;
    background: none;
    border-radius: 25px;
    cursor: pointer;
    background: #ffffff;
  }

  .newDocumentWrapper div button {
    color: #003bb3;
    font-size: 20px;
  }

  .profileWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profileWrapper div {
    display: none;
  }

  .profileWrapper #avatar {
    width: 30px;
    height: 30px;
    background: #f1f1f1 url('../../assets/icons/avatarIcon.svg') no-repeat;
    background-size: cover;
    border: 4px solid #ffffff;
    border-radius: 16px;
    margin-top: 280px;
    display: inline-block;
  }

  .btnTextWrapper {
    padding: 0 14px;
  }
}

.sidebar {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__btn {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hideBtnWrapper {
  padding: 8px;
  &:hover {
    background: #194fbb;
  }
}

.hideBtn {
  color: #ffffff;
  cursor: pointer;
  background-image: url('../../assets/icons/hideMenuIcon.svg');
  background-size: cover;
  display: inline-block;
  width: 10px;
  height: 10px;
}

.menu {
  
  padding: 0 1rem 6.25rem;
  font-size: 1rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  &__section {
    padding: 1.875rem 1.526rem;
    min-height: 200px;
  }
  &__list {
    font-size: 0.875rem;
  }
  &__label {
    margin-left: 1rem;
  }
  &__item {
    &:after {
      content: '';
      height: 15px;
      width: 4px;
      background-color: black;
      position: absolute;
      left: -20px;
      right: 0;
      transition: all 300ms ease-out;
    }
  }
  &__link {
    padding: 1rem;
    font-size: 1rem;
    color: #4A494E;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 2px;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    &.active {
      background: rgba(255, 255, 255, 0.3);
      font-weight: 600;
      &:after {
        content: '';
	      height: 60px;
		    width: 11px;
		    background-color: #003BB3;
        border-radius: 9px;
		    position: absolute;
		    left: -5px;
		    right: 0;
		    transition: all 300ms ease-out;
      }
      button {
        svg{
          path{
            fill: #003BB3 !important;
          }
        }
      }
    }
  }
  &__content {
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: #fff;
  }
  &__subsection {
    padding: 1rem 1.25rem 1rem;
    border-bottom: 2px solid #003bb3;
  }
  &__pre {
    font-size: 12px;
    color: #f9f9f9;
  }
  // TODO: remove styling below
  div {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 15px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    transition: 0.5s ease;
    font-size: 16px;
    cursor: pointer;

    p {
      margin-left: 15px;
    }

    i {
      margin-right: 15px;
      display: inline-block;
    }
  }
}

.content {
  &__list {
  }
  &__item {
    font-size: 0.75rem;
    position: relative;
    line-height: 1.5;
    padding-left: 1rem;
    &:before {
      content: '';
      background: #fff;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      display: block;
      position: absolute;
      left: 0px;
      top: 5px; /* (line-height (18px / 1.5) - height of circle (8px)) / 2  */
    }
  }
}

// TODO: remove styling below
.activeLink {
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s ease;
  font-weight: 600;
}

// TODO: change icons to svgs
.homePageIcon {
  background-image: url('../../assets/icons/homeIcon.svg');
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  height: 15px;
  width: 15px;
}

.myDocumentsIcon {
  background-image: url('../../assets/icons/myDocsIcon.svg');
  height: 15px;
  width: 15px;
}

.settingsIcon {
  background-image: url('../../assets/icons/settingsIcon.svg');
  height: 15px;
  width: 15px;
}

.menu div:hover {
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s ease;
  font-weight: 600;
}

.newDocumentWrapper {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0 14px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  max-width: 205px;
  width: 100%;
  outline: none;
  border: none;
}

.btnTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #003bb3;
    line-height: 10px;
    font-size: 16px;
  }
}

.newDocumentBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 14px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
}

.sliderBtn {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25px;
  width: 100%;

  div {
    border-radius: 5px;
    background: #ffffff;
    padding: 5px;
    width: 5%;
  }
}

.profileComplete {
  padding: 25px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.avatarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.7;
}

.avatar {
  width: 30px;
  height: 30px;
  background: #f1f1f1 url('../../assets/icons/avatarIcon.svg') no-repeat;
  background-size: cover;
  border: 4px solid #ffffff;
  border-radius: 100px;
}

.profileCompleteText {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;

  span {
    font-weight: 500;
    font-size: 20px;
    margin-left: 5px;
  }
}

.progressLineWrapper {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  height: 10px;
}

.progressLine {
  position: absolute;
  width: 30%;
  opacity: 1;
}

.progressLineStatic {
  position: absolute;
  background: #ffffff;
  opacity: 0.4;
  border-radius: 10px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.complete_link {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #f9f9f9;
  opacity: 0.7;
}

.statusTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #f9f9f9;
}

.statusText {
  font-family: 'Mulish', sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.upgradeBtn {
  padding: 10px;
  background: #ffffff;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #003bb3;
  margin-top: 15px;
  border: 1px solid #ffffff;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
}
