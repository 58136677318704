.header {
  font-size: 1.25rem;
  font-weight: 600;
}

.lists {
  list-style-type: disc;
  &__item {
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
    font-size: 0.875rem;
  }
}

.document {
  &__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100%;
    background: #f9f9f9;
    z-index: 100;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin: 0.625rem;
    padding: 0.625rem;
    border-radius: 0.25rem;
    .btn {
      width: 48px;
      height: 48px;
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      border-radius: 5px;
    }
  }

  &__content {
    padding-left: 1rem;
    .meta {
      display: flex;
      justify-content: center;
    }
    .page {
      max-width: 834px;
      margin: 0 auto;
      &.alt {
        margin: 0;
      }
    }
  }

  &__view {
    position: relative;
  }

  &__aside {
    background: #fff;
    padding: 1rem;
  }
}

.clusters {
  margin-right: -6px;
}

.fieldset {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  border: 1px solid lightgray;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: 1px solid lightgray;
  position: relative;
  margin: 1rem 0;
  &__legend {
    color: #fff;
    background: #003bb3;
    border-radius: 2px;
    padding: 0 0.5rem;
  }
  &__btn {
    position: absolute;
    top: 0;
    right: 1rem;
    top: -1.5rem;
  }
}

canvas {
  margin: 0 auto;
}

.transaction__cost {
  margin-top: 20px;

  &__header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__group {
    display: flex;
    justify-content: space-between;

    h3, p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }
}

