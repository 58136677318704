.select {
  &__wrapper {
    position: relative;
  }
  &__btn {
    height: 48px;
    border: 1px solid #949494;
    border-radius: 8px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #575a65;
    padding: 0.3rem 1rem;
  }
  &__icon {
    display: flex;
    align-items: center;
  }
  &__dropdown {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    max-height: 200px;
    z-index: 1000;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.875rem;
    &:hover {
      background: #f5f5f4;
    }
    &.active {
      color: #363740;
      background: #f2f2f2;
    }
  }

  &__disabled {
    background: #f4f4f4;
    color: #cacaca;
    cursor: default;
  }
}
