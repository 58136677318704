@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

.upload {
  display: inline-flex;
  background: #FAFAFA;
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23003BB3FF' stroke-width='2' stroke-dasharray='9%2c 12' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
  border: 2px solid #7b7171;
  border-style: dashed;
  border-radius: 1rem;
  padding: 1rem 1.25rem;
  padding-right: 25px;
  margin-bottom: 0.625rem;
  margin-top: 0rem;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    // height: 150px;
    height: auto;
    width: 485px;
  }
  
  &__wrapper {
    display: inline-flex;
    flex-direction: column;
    
  }

  &__label {
    font-size: 0.875rem;
    line-height: 1.315rem;
    color: #9F9E9E;
    font-weight: 500;
    margin-bottom: 6px;
  }

  &__icon {
    margin-right: 1rem;
  }

  &__title {
    color: #505050;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  &__caption {
    font-size: 0.75rem;
    color: #7b7171;
    max-width: 187px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: inline-block;
  }

  &__meta {
    color:#9F9E9E;
    font-weight: 400;
    font-size: 0.75rem;
  }
}
