@media (min-width: 768px) {
  .request_container {
    background: #ffffff;
    padding: 17px 10px 17px 28px;
    border-radius: 8px;
    height: 100vh;
    
    &__header {
      font-weight: 600;
      color: #363740;
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 10px;
      
    }
    &__flex {
      display: flex;
      justify-content: space-between;
      gap:1rem;
      
    }
  }
}


.request_container{
  &__flex {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    overflow: auto;
  }
  &__header {
    font-weight: 600;
    color: #363740;
    font-size: 18px;
   
    margin-top: 20px;
    
  }
}


.empty__state__icon {
  text-align: center;
  margin: 100px;
}

.join_button {
  position: absolute;
  right: 50px;
}

.session_container {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 20px 0px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  &__document_time {
   
    min-height: 80px;
    padding: 12px 20px 12px 20px;
    border: 2px solid #eef5ff;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      // width: 27%;
      margin-right: 20px;
    }
  }
  &__video_session {
   
    min-height: 78px;
    padding: 12px 20px;
    border: 2px solid #eef5ff;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 20px;
    text-align: center;
    @media screen and (min-width: 768px) {
      width: 24%;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 14px;
    color: #7b7171;
    margin-bottom: 10px;
  }
  &__link {
    font-weight: 400;
    font-size: 11px;
    text-decoration-line: underline;
    color: #003bb3;
  }
  &__timeframe {
    font-weight: 400;
    font-size: 11px;
    color: #000000;
  }
  &__document_link {
    display: flex;
    align-items: flex-start;
    
    img {
      margin-right: 10px;
    }
  }
}

