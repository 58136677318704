.error {
    text-align: center;
    margin: 15rem 0rem;
  
    .heading {
      top: 217px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 72px;
      color: #003bb3;
    }
  
    .paragraph {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #121625;
      font-family: 'Poppins';
    }
    .goBack {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #003bb3;
      margin-top: 2rem;
    }
  }
  
  