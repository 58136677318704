@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');

@font-face {
  font-family: icomoon;
  src: url('/assets/fonts//icomoon.eot?3p73j4Ta');
  src: url('/assets/fonts//icomoon.eot?3p73j4Ta#iefix') format('embedded-opentype'),
    url('/assets/fonts//icomoon.ttf?3p73j4Ta') format('truetype'), url('/assets/fonts//icomoon.woff?3p73j4Ta') format('woff'),
    url('/assets/fonts//icomoon.svg?3p73j4Ta#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class*='hasIcon__'] {
  display: flex;
  &::before {
    @extend #iconFont;
    font-family: icomoon;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    // line-height: 1.5rem;
  }
  &.noText {
    padding-left: 0.75em;
    padding-right: 0.75em;
    span {
      display: none;
    }
  }
  &:not(.noText) {
    &::before {
      margin-right: var(--indent10);
    }
  }
  &.iconReverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    &:not(.noText) {
      &::before {
        margin-left: var(--indent10);
        margin-right: unset;
      }
    }
  }
}
// button[class*="hasIcon__"] {
// 	&::before {
// 		line-height: unset;
// 	}
// 	&:active {
// 		&::before {
// 			@extend #noTransition;
// 			// line-height: calc(1.5rem + 0px);
// 			// transform: translateY(1px);
// 		}
// 	}
// }
.hasIcon__alert {
  &::before {
    content: '\e90e';
  }
}
.hasIcon__arrow-down {
  &::before {
    content: '\e90f';
  }
}
.hasIcon__bell {
  &::before {
    content: '\e90a';
  }
}
.hasIcon__bookmark {
  &::before {
    // content: '\e91a';		//figma
    content: '\e918'; //material
  }
  &.isActive {
    &::before {
      content: '\e919'; //material
    }
  }
}
.hasIcon__chevron-left {
  &::before {
    content: '\e908';
  }
}
.hasIcon__click {
  &::before {
    content: '\e925';
  }
}
.hasIcon__correct,
.hasIcon__checkmark {
  &::before {
    content: '\e90d';
  }
}
.hasIcon__checkmark-filled {
  &::before {
    content: '\e916';
  }
}
.hasIcon__doc {
  &::before {
    // content: '\e904';		//figma
    content: '\e91f'; //material
  }
  &.isActive {
    &::before {
      // content: '\e903';	//figma
      content: '\e920'; //material
    }
  }
}
.hasIcon__eye {
  &::before {
    content: '\e91e'; //figma
    // content: '\e91b';		//material
  }
  &.isActive {
    &::before {
      content: '\e91d'; //figma
      // content: '\e91a';	//material
    }
  }
}
.hasIcon__filter {
  &::before {
    content: '\e912';
  }
}
.hasIcon__gear {
  &::before {
    content: '\e928';
  }
}
.hasIcon__gridview {
  &::before {
    content: '\e910';
  }
}
.hasIcon__home {
  &::before {
    // content: '\e906';		//figma
    content: '\e922'; //material
  }
  &.isActive {
    &::before {
      // content: '\e905';	//figma
      content: '\e923'; //material
    }
  }
}
.hasIcon__listview {
  &::before {
    content: '\e911';
  }
}
.hasIcon__logout {
  &::before {
    content: '\e90a';
  }
}
.hasIcon__logout-door {
  &::before {
    content: '\e927';
  }
}
.hasIcon__long-arrow-left {
  &::before {
    content: '\e917';
  }
}
.hasIcon__long-arrow-right {
  &::before {
    content: '\e914';
  }
}
.hasIcon__menu {
  &::before {
    content: '\e907';
  }
}
.hasIcon__question {
  &::before {
    content: '\e90b';
  }
}
.hasIcon__search {
  &::before {
    content: '\e913';
  }
}
.hasIcon__settings {
  &::before {
    // content: '\e902';		//figma
    content: '\e921'; //material
  }
}
.hasIcon__signature {
  &::before {
    content: '\e926';
  }
}
.hasIcon__vellip {
  &::before {
    content: '\e909';
  }
}

[class*='hasBullet__'] {
  li {
    padding-left: 2rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    position: relative;
    &::before {
      @extend [class*='hasIcon__']::before;
      left: 0;
      margin-right: 0;
      position: absolute;
    }
  }
}
.hasBullet__checkmark {
  li {
    &::before {
      content: '\e915';
    }
  }
}
.hasBullet__checkmark_filled {
  li {
    &::before {
      content: '\e916';
    }
  }
}
