.menu {
  position: relative;
  display: inline-block;
  &__dropdown {
    position: absolute;
    background: #fff;
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    left: -2rem;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    font-size: 0.75rem;
    z-index: 100;
    max-width: 250px;
    &.large {
      min-width: 226px;
      left: 0;
    }
    &.left {
      left: auto;
      right: -0.5rem;
    }

    .label {
      font-size: 0.625rem;
      text-transform: uppercase;
      display: block;
      padding-top: 0.5rem;
      &__max {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item {
      font-size: 0.875rem;
      padding: 0.625rem 1rem;
      display: flex;
      white-space: nowrap;
      align-items: center;
      border-top: 1px solid #d9d9d9;
      width: 100%;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover {
        background: #f9f9f9;
      }
    }
  }
}
