.auth_wrapper {
  &__content1 {
    padding: 15px 20px 15px 20px;
    // overflow: scroll;
    background: url('../../assets/img/background-top-stamp.svg') no-repeat;
    background-position: top right;

    @media (min-width: 1024px) {
      padding: 155px 103px 4rem 74px;
    }
  }

  &__content2 {
    margin: 140px 50px;
  }

  &__imageContent {
    margin: 0px 50px 140px 50px;
  }

  &__image {
    width: 40%;

    @media (min-width: 1024px) {
      width: '277px';
    }
  }

  &__title {

    font-size: 30px;
    color: #000000;
    margin: 10px 0px 0px 0px;
  }

  &__smallTitle {
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    color: #003bb3;
  }

  &__details {
    font-size: 15px;
    line-height: 24px;
    // color: #98a6ad;
  }

  &__signUpImage {
    left: 170px;
    top: 1px;
    position: relative;
  }

  &__checkbox {
    font-size: 9px;
    color: rgb(32, 73, 162);
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
  }

  &__sideBtn {
    margin: 20px 0px;
  }

  &__resend__mail {
    font-size: 14px;
    color: #98a6ad;
    margin-top: 20px;
  }
}

.title_wrapper {
  display: flex;
  justify-content: space-between;
}

.form {
  margin-top: 20px;
}

.modalWrapper {
  text-align: center;
}

.auth_image_wrapper {
  display: flex;
  // width: 100vw;
  // height: 100vh;
  position: relative;
  flex-direction: column;

  @media (min-width: 1024px) {
    position: fixed;
    flex-direction: row;
  }
}

.auth_image_wrapper::after {

  @media screen and (min-width: 1024px) {
    content: url('../../assets/img/background-buttom-stamp.svg');
    bottom: 0px;
    right: 0;
    position: fixed;
  }
}

.auth_signup_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.auth_signup_wrapper::after {

  @media screen and (min-width: 1024px) {
    content: url('../../assets/img/background-buttom-stamp.svg');
    bottom: 0px;
    right: 0;
    position: fixed;
  }
}

// .phoneLabelWrapper {
//   margin-bottom: 0.8rem;
// }

.auth_caption {
  margin-top: 31px;
}

.phoneLabel {
  position: relative;
  color: #767676;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
}

.auth_Password_form {
  width: 100%;

  @media (min-width: 1024px) {
    width: 576px;
    height: 100vh;
    // overflow-y: scroll ;
  }
}

.auth_form {
  // overflow-y: scroll;
  width: 100%;

  @media (min-width: 1024px) {
    width: 35vw;
  }
}

.auth_image {
  display: none;
  flex-grow: 1;
  background-size: cover;
  width: 70vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1024px) {
    display: block;
  }
}

.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.link {
  font-size: 16px;
  color: #000000;
  margin: 10px 0px;

  a {
    font-size: 18px;
    color: #000000;
  }

  &__mail {
    font-size: 14px;
    color: #7b7171;
    font-weight: bold;
    text-decoration: none;
  }

  &__text {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__innerWrapper1 {
    width: 48%;
  }

  &__innerWrapper2 {
    width: 48%;
  }
}

.error {
  color: #ff0000;
  font-size: 10px;
  margin-bottom: 1rem;
  // margin-top: -0.5rem;
}

.form_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;

  p {
    font-size: 14px;
    color: #003bb3;
  }
}