.isNeutral {
  &,
  &[type='submit'],
  &[type='button'] {
    &,
    &:link,
    &:visited {
      // background: var(--clr-gray-dark);
      box-shadow: none;
      background-color: transparent;
      height: auto;
      padding: 0;
    }
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: var(--clr-header);
      box-shadow: none;
      background-color: transparent;
      height: auto;
      line-height: inherit;
    }
    &:hover,
    &:focus,
    &:active {
      background: #ddd;
      box-shadow: none;
      background-color: transparent;
      height: auto;
    }
  }
}
.noShadow {
  &,
  &[type='submit'],
  &[type='button'] {
    &,
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
.isTransparent {
  &,
  &[type='submit'],
  &[type='button'] {
    background: 0;
    color: var(--clr-text-lite);
    &,
    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
    &:hover,
    &:focus {
      color: var(--clr-header);
    }
  }
}
button {
  cursor: pointer;
  &.addNew {
    &[type='submit'],
    &[type='button'] {
      @extend #buttonWhite;
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4.5em;
      text-align: left;
      &::after {
        content: '+';
        display: inline-block;
        background: var(--clr-gray-lite);
        text-align: center;
        font-size: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        border-radius: 100%;
        margin-left: 2rem;
        flex: 0 0 auto;
      }
      &:active {
        line-height: unset;
        span,
        &::after {
          transform: translateY(1px);
        }
      }
    }
  }
}

.icon {
  &__wrapper {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #f9f9f9;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &--sm {
      height: 24px;
      width: 24px;
      margin-left: auto;
    }
    &--right {
      margin-left: auto;
    }
  }
}
