.calendarHeader {
    margin: 35px 0 15px 0;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.calendarCaption {
    margin-bottom: 15px;
    font-size: 14px;
}

.calendarContainer {
    display: flex;
    &__section_one {
        margin-right: 65px;
    }
    &__section_two {
        margin-left: 65px;
    }
}

.time {
    background: #F2F2F2;
    padding: 12px 40px;
    font-weight: 500;
    font-size: 14px;
    color: #363740;
    border-radius: 2px;
    &__timeSelected {
        padding: 12px 40px;
        background: #003BB3;
        color: white;
    }
}

.timeContainer {
    h3{
        margin-bottom: 8px;
    }
};
.calendarButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.calendarPopulationButton {
    p{
        margin: 25px 0 15px 0;
        font-size: 14px;
        font-weight: 600;
    }
}

.modalContainer {
    text-align: center;
    &__header {
      font-weight: 600;
      font-size: 26px;
      line-height: 48px;
      color: #003bb3;
      margin-bottom: 9px;
    }
    &__caption {
      padding-bottom: 30px;
      border-bottom: 1px solid #cecece;
      font-size: 14px;
    }
    &__btn {
      button {
        margin: 31px 12px 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

.calendarButtonContainer {
    width: 77%;
    display: flex;
    justify-content: space-between;
}