.pagination {
  display: flex;
  align-items: center;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    width: 30px;
    height: 25px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 21px;
    &.active {
      color: #fff;
      background: #003bb3;
    }
  }
  &__input {
    width: 56px;
    height: 36px;
    font-size: 0.875rem;

    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #cacaca;
    margin-left: 1rem;
  }
  &__form {
    display: flex;
    align-items: center;
    color: #7a7c7e;
  }
}
