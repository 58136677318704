.modal,
.modal2 {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  background: rgba(163, 156, 156, 0.56);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__content {
  background-color: #fff;
  min-width: 414px;
  max-width: 840px;
  min-height: 256px;
  max-height: 700px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  padding: 21px 30px;
  overflow: auto;
  &.md {
    max-width: 550px;
    margin: auto;
  }

  hr {
    width: 100%;
    height: 0px;
    margin: 5px 0px 12px;
  }

  .button {
    border-radius: 4px;
    padding: 0 1.25rem;
    height: 48px;
    width: 94px;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #363740;
    background: #f2f2f2;
    cursor: pointer;
  }
}

.modal__content2 {
  background-color: #fff;
  max-width: 989px;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
  float: right;
  right: 0;
  overflow: auto;
  margin-bottom: 50px;

  .header {
    height: 54px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid #f2f2f2;

    h1 {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #434854;
      margin-bottom: 0;
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 28px;
    box-sizing: border-box;
  }
}

.close_modal {
  color: #a4a7b7;
  float: right;
  font-size: 30px !important;
}

.close_modal:hover,
.close_modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.notifs {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;

  &__single {
    min-height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #cecece;

    .left {
      width: 75%;
      display: flex;
      align-items: center;

      .action {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #363740;
        margin-left: 8px;
        text-transform: lowercase;

        span {
          text-transform: capitalize;
        }
      }

      .activity {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #363740;
        margin-left: 8px;
      }
    }

    .image {
      width: 35px;
      height: 35px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 23%;

      h4 {
        margin: 8px 0;
        font-family: 'Poppins';
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #363740;
        display: flex;
        justify-content: flex-end;
      }
    }

    &_pointer {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #363740;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal__content,
  .modal2 .modal2__content {
    max-width: 100% !important;
    width: 100% !important;

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      box-sizing: border-box;
    }
  }
}
